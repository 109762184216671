import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import ProfilePhoto from "./ProfilePhoto";
import { NameInfo } from "./NameInfo";
import EmailInfo from "./EmailInfo";
import PasswordInfo from "./PasswordInfo";
import { UsernameInfo } from "./UsernameInfo";
import ProviderConnect from "./ProviderConnect";
import LinkCredentials from "./LinkCredentials";
import { AccountBodyWrapper } from "../AccountBodyWrapper";
import styles from "./styles.module.scss";

interface ProviderData {
  providerId: string;
  photoURL?: string;
  displayName?: string;
}

interface UserPublic {
  [key: string]: any;
}

interface UserPrivate {
  [key: string]: any;
}

interface RootState {
  auth: {
    providerData: ProviderData[];
  };
  user: {
    public: UserPublic;
    private: UserPrivate;
  };
}

function Settings() {
  const [showLinkCredentials, toggleLinkCredentials] = useState(false);
  const providerData = useSelector(
    (state: RootState) => state.auth.providerData
  );

  return (
    <AccountBodyWrapper>
      <Flex flexDirection="column">
        <Helmet title="Settings | Online Dance Classes and Tutorials" />
        <Flex alignItems="center" flexDirection="column">
          <Flex flexDirection="column" className={styles.settingsContainer}>
            {!showLinkCredentials && (
              <>
                <ProfilePhoto providerData={providerData} />
                <div className={styles.headerWrapper}>
                  <H2>Account Settings</H2>
                </div>
                <UsernameInfo />
                <NameInfo />
                <EmailInfo
                  providerData={providerData}
                  toggleLinkCredentials={(toggle: boolean) =>
                    toggleLinkCredentials(toggle)
                  }
                />
                <PasswordInfo />
                <ProviderConnect providerData={providerData} />
              </>
            )}
            {showLinkCredentials && (
              <LinkCredentials
                toggleLinkCredentials={(toggle: boolean) =>
                  toggleLinkCredentials(toggle)
                }
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </AccountBodyWrapper>
  );
}

export default Settings;
